import { initFederation } from "@angular-architects/module-federation";

fetch(`/assets/modules/${window.location.hostname}.manifest.json`, {
  cache: "no-store",
})
  .then(response => response.json())
  .catch(() => ({}))
  .then(manifest => initFederation(manifest))
  .catch(err => console.error(err))
  .then(() => import("./bootstrap"))
  .catch(err => console.error(err));
